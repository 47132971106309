import * as React from "react";
import { graphql } from "gatsby";
import Layout from "../components/Templates/Layout";
import Meta from "../utils/Meta";
import Banner from "../components/Hero/Banner";
import SmartText from "../utils/TextHandler";

// Markup
const FourOhFourPage = (props) => {
  const { data } = props;

  let doc;
  if (data) {
    doc = JSON.parse(data.index.content);
  }
  const openGraph = {
    type: "website",
    title: doc.name,
    description: doc.description,
  };

  return (
    <Layout>
      <Meta openGraph={openGraph} meta={doc.meta} />
      {(doc.error_title || doc.error_body || doc.error_image) && (
        <Banner
          title={doc.error_title && doc.error_title}
          description={doc.error_body && doc.error_body}
          image={doc.error_image && doc.error_image}
        />
      )}
    </Layout>
  );
};

export default FourOhFourPage;

export const QUERY = graphql`
  query FourOhFourPageQuery {
    index: storyblokEntry(slug: { eq: "settings" }) {
      content
    }
  }
`;
